div#root {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  display: grid;
  grid-template-columns: 200px 1fr;
  overflow: hidden;
  grid-template-rows: 50px 1fr 50px;
}
.ui.form .field.wide > .selection.dropdown {
  max-width: 100% !important;
  min-width: 100% !important;
}
header {
  grid-row: 1;
  grid-column: 1 / 3;
  background: #004594;
  display: flex;
  border-bottom: 1px solid rgba(142, 142, 142, 0.67);
  z-index: 2;
}
.loginGrid {
  grid-column: 1 / 3;
  grid-row: 1 / 4;
}
#centerspace {
  flex: 1;
}
#redbar {
  background: #cc1120;
  width: 15px;
}
.topbutton {
  background: black;
  width: 80px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.topbutton:hover {
  background: #222;
}
a.topbutton {
  font-size: 40px;
  font-weight: bold;
  color: white;
  line-height: 50px;
}
.visible.transition.ui.message {
  display: flex !important;
}
.topbutton img {
  height: 30px;
  align-self: center;
  width: 30px;
}
#logo {
  height: 100px;
  width: 199px;
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
}
.fullscreen #logo {
  height: 50px;
  border-bottom: 1px solid #aaa;
  width: 100px;
}
div#nav {
  grid-row: 2 / 4;
  background: #004594;
  margin-top: 50px;
  border-right: 1px solid #aaa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
div#nav #makesmall {
  width: 50px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
div#nav #makesmall:hover {
  opacity: 0.7;
}
div#nav #makesmall img {
  align-self: center;
  width: 30px;
  transform: rotate(180deg);
  height: 30px;
}
div#nav-linkwrapper {
  display: flex;
  flex-direction: column;
}
.navlink {
  display: block;
  height: 80px;
  padding: 16px 40px 16px 15px;
  line-height: 45px;
  color: #ffffff;
  text-shadow: 0px 1px 0px #000000;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
}
.navlink:hover {
  background: #0051ae;
  color: #ffffff;
  text-shadow: 0px 1px 0px #000000;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.loginPa {
  background-image: url(../images/background.jpg);
  background-size: cover;
  background-position: bottom center;
}
.loginPa .loginview,
.loginPa .flagcollection {
  margin: 35px auto 10px !important;
  max-width: 400px;
  min-width: 400px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.loginPa .ui.padded.segment.flagcollection {
  padding: 8px 18px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px !important;
}
.loginPa .ui.padded.segment.flagcollection .flagWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px;
}
.loginPa .ui.padded.segment.flagcollection .flagname {
  color: #777;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  margin-top: 4px;
}
.loginPa .ui.padded.segment.flagcollection img {
  border: 1px solid #aaa;
  cursor: pointer;
  max-width: 40px;
}
footer {
  grid-row: 3;
  grid-column: 1 / 3;
  display: flex;
  background: #004594;
  border-top: 1px solid #aaa;
}
footer #makebig {
  width: 50px;
  display: flex;
  justify-content: center;
}
footer #makebig img {
  align-self: center;
  width: 30px;
  height: 30px;
}
footer .footlink {
  flex: 1;
  display: block;
  text-align: center;
  line-height: 50px;
}
footer #makebig,
footer .footlink {
  border-right: 1px solid #aaa;
  color: #ffffff;
  text-shadow: 0px 1px 0px #000000;
  cursor: pointer;
  font-weight: bold;
}
footer #makebig:hover,
footer .footlink:hover {
  background: #0051ae;
  color: #ffffff;
  text-shadow: 0px 1px 0px #000000;
}
main {
  grid-row: 2 / 4;
  grid-column: 2 / 3;
  overflow: auto;
  position: relative;
}
main.fullscreen {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}
main.noFooter {
  grid-row: 2 / 4;
}
body {
  font-family: system-ui;
}
.ui.active.button {
  background-color: #333;
  color: rgba(255, 255, 255, 0.95);
}
.ui.active.button:hover {
  background-color: #555;
  color: rgba(255, 255, 255, 0.95);
}
.labelledButtonWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  align-items: center;
}
.labelledButtonWrapper:last-child {
  margin-right: 0;
}
.ui.small.message.messageMargin {
  margin-top: 0;
}
i.requestPopupTrigger {
  /*font-size: 14px;
    color: rgba(0,0,0,0.5);
    width: 100%;*/
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  width: 22px;
  line-height: 11px;
  left: calc(50% - 11px);
  position: relative;
}
.dropZone {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  justify-content: center;
  align-items: center;
  font-size: 4em;
  color: #777;
}
.dropZone * {
  pointer-events: none;
}
.importantSupplier:before {
  content: "\f1da";
  transform: translateY(-150%);
  background: 0 0!important;
  opacity: 1;
  margin: 0 0.4rem 0 0;
  width: 1.18em;
  height: 1em;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  speak: none;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #888;
}
@media print {
  @page {
    margin: 1cm;
  }
}
.labelledButtonLabel {
  font-size: 70%;
  text-transform: uppercase;
  opacity: 0.7;
  font-weight: 200;
}
.hiddenForm {
  margin-top: 15px;
}
i.icon.messageTopRightButton {
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem !important;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.8;
}
i.icon.messageTopRightButton:hover {
  opacity: 1;
}
.questionHeader {
  display: flex;
  align-items: center;
}
.questionHeader .questionHeaderLabel {
  flex: 1;
}
.questionarySegment {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.questionarySegment .namecell {
  flex: 1;
}
.processNames {
  width: 100%;
  margin-top: 2px;
  margin-right: 8px;
}
.processReportItem {
  position: relative;
  flex-flow: row wrap;
  align-items: center;
  display: flex;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 2.53333333%) inset;
  padding-left: 6px;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  margin-top: 8px;
}
.processReportItem .spacer {
  flex-grow: 1;
  height: 1px;
}
.processReportItem .processSwatch {
  height: 26px;
  width: 30px;
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-right: 5px;
  border-radius: 4px;
}
.processReportItem .ui.button {
  vertical-align: middle;
  margin: 0;
}
.metacomHeader {
  font-weight: bold;
  /* text-align: center; */
  text-transform: uppercase;
  color: #6b6b6b;
  margin-bottom: 5px;
  font-size: 14px;
}
.infoLabel {
  display: flex;
}
.infoLabel label {
  display: block;
  flex: 1;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}
.metacomWrapper {
  border-bottom: 1px solid #d4d4d5;
  margin-bottom: 10px;
}
.infoLabelMessageWrapper {
  opacity: 0;
  max-height: 0;
  transition: max-height 0.15s ease-out, opacity 0.15s ease-out;
  overflow: hidden;
}
.infoLabelMessageWrapper.visibleX {
  display: block;
  opacity: 1;
  max-height: 200px;
  transition: max-height 0.25s ease-in, opacity 0.25s ease-in;
}
.infoLabelMessageWrapper .infoLabelMessage {
  margin: 0 0em 0.5em !important;
  padding: 0.5em 1em !important;
}
.metacomSelector .ui.loader:after {
  border-color: #767676 transparent transparent !important;
}
.metacomSelector .ui.loader:before {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.ui.table .heightLimit td:not(.padded) {
  padding: 0;
}
.heightTrigger {
  cursor: pointer;
}
.heightTrigger:hover {
  opacity: 0.8;
}
.dragger {
  display: none;
}
.sizeControlled .dragger {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  border-left: 1px solid #e3e4e5;
  background: rgba(0, 0, 0, 0.05);
  cursor: col-resize;
}
.sizeControlled .dragger.react-draggable-dragging {
  border-right: 1px solid #e3e4e5;
  background: rgba(0, 0, 0, 0.25);
}
.sizeControlled {
  width: initial !important;
}
.relativeTh th {
  position: relative;
}
.dl_jobname {
  font-weight: bold;
  margin-bottom: 5px;
}
.heightLimited {
  padding: 0.78571429em 0.78571429em;
  transition: max-height 1s ease-in-out;
  overflow: hidden;
}
.closed .heightLimited {
  position: relative;
  max-height: 80px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.closed .heightLimited .gradient,
.closed .heightLimited:not(.hasGradient):after {
  content: "";
  position: absolute;
  height: 0.78571429em;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}
a.inlineLink {
  cursor: pointer;
}
a.inlineLink:hover {
  color: #7aa8d6;
}
.metacomError {
  margin-bottom: 10px;
}
.ui.grey.menu {
  background-color: #e0e1e2;
}
.ui.inverted.blue.menu {
  background-color: #004594;
}
.matChooserTest i.icon {
  margin-left: 10px;
}
.ui.selection.dropdown.wideDrowdown {
  min-width: 18em;
}
.sortName {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
  color: #666;
}
th.center.sorted {
  display: flex;
  justify-content: center;
}
.agfModal.ui.fullscreen.modal {
  left: initial !important;
}
.agfModal.ui.fullscreen.modal .bold {
  font-weight: bold;
}
.agfModal.ui.fullscreen.modal .red.item {
  background-color: #f9b8be;
}
.agfModal.ui.fullscreen.modal .red.item:hover {
  background-color: #eee;
}
.agfModal.ui.fullscreen.modal .green.item {
  background-color: #acf1bc;
}
.agfModal.ui.fullscreen.modal .green.item:hover {
  background-color: #eee;
}
.ui.dimmer {
  z-index: 1002;
}
#dayViewPage {
  display: flex;
  flex-direction: column;
}
#dayViewPage #map_map {
  min-height: 400px;
  border-bottom: 1px solid #aaa;
}
#dayViewPage .tableWrapper {
  padding: 15px;
}
#dayViewPage .buttonLine {
  position: sticky;
  top: 0px;
  background: white;
}
#dayViewPage .ui.sortable.table thead th.red:after {
  color: #cc1120;
}
#dayViewPage .ui.sortable.table thead th.green:after {
  color: green;
}
#dayViewPage .nothingPlaceholder {
  text-align: center;
  font-size: 30px;
  line-height: 1;
  color: #ddd;
}
#dayViewPage .processIcon {
  font-size: 30px;
  margin-bottom: 4px;
}
#dayViewPage .processIcon:before {
  margin: 0;
  line-height: 1;
}
#dayViewPage .labelContainer {
  display: flex;
  flex-wrap: wrap;
}
#dayViewPage .labelContainer .resourceLabel {
  /* margin: 10px; */
  /* display: inline-block; */
  display: flex;
  align-items: center;
  border: 1px solid #aaa;
  border-radius: 5px;
  overflow: hidden;
  line-height: 1;
  padding-right: 3px;
  margin: 0 3px 3px 0;
  cursor: pointer;
  /* max-width: 160px; */
}
#dayViewPage .labelContainer .resourceLabel:hover {
  background: #eee;
}
#dayViewPage .labelContainer .resourceLabel.hasComment {
  font-style: italic;
}
#dayViewPage .labelContainer .resourceLabel.request {
  background: #ddd;
}
#dayViewPage .labelContainer .resourceLabel.request:hover {
  background: #ccc;
}
#dayViewPage .labelContainer .resourceLabel .labelImage {
  min-width: 24px;
  background-size: cover;
  height: 24px;
  background-color: #aaa;
  background-position: center;
  border-right: 1px solid #aaa;
}
#dayViewPage .labelContainer .resourceLabel .labelText {
  flex: 1;
  padding: 0 10px 0 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
  overflow: hidden;
}
#dayViewPage .labelContainer .resourceLabel .labelAddText {
  font-size: 90%;
  padding-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60px;
  overflow: hidden;
}
#dayViewPage .labelContainer .resourceLabel i.icon {
  color: #555;
  cursor: pointer;
}
#dayViewPage .labelContainer .resourceLabel i.icon:hover {
  color: #777;
}
.questionaryModal .accordion .title {
  display: flex;
}
.questionaryModal .accordion .title .acctitl {
  flex: 1;
}
.ui.divider.constraintDivider {
  margin-top: 30px;
}
.xModal .ui.menu {
  margin-bottom: 0;
}
.materialChooserModal .ui.menu {
  margin-bottom: 0;
}
.materialChooserModal .ui.accordion .title {
  font-size: 22px;
  display: flex;
  align-items: center;
}
.materialChooserModal .ui.accordion .title .matChooserType {
  margin-right: 10px;
}
.materialChooserModal .ui.accordion .title .matChooserInc {
  color: #cc1120;
  margin-left: 10px;
}
.materialChooserModal .ui.accordion .title .matChooserName {
  color: #666;
  font-style: italic;
}
.materialChooserModal .ui.accordion .title .matChooserName.incompatible {
  color: #cc1120;
}
.materialChooserModal .ui.segment.paddingLess {
  padding: 0;
}
.materialChooserModal .ui.segment.paddingLess .nothing {
  padding: 10px;
  text-align: center;
}
.materialChooserModal .ui.segment.paddingLess .matChooserLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d4d4d5;
  padding: 4px 20px;
}
.materialChooserModal .ui.segment.paddingLess .matChooserLine:last-child {
  border-bottom: none;
}
.ui.button.specialMatButton {
  display: flex;
  padding: 0;
  align-items: stretch;
  overflow: hidden;
}
.ui.button.specialMatButton .iconcont {
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.07);
  display: block;
  padding: 0.78571429em;
}
.ui.button.specialMatButton .iconcont i.icon {
  margin: 0 !important;
}
.ui.button.specialMatButton .incompatible {
  color: rgba(255, 255, 255, 0.6);
  background: #cc1120;
  display: block;
  padding: 0.78571429em;
}
.ui.button.specialMatButton .incompatible i.icon {
  margin: 0 !important;
}
.ui.button.specialMatButton .matChooserName {
  align-self: center;
  padding: 0.78571429em 1.5em 0.78571429em;
}
.ui.label.matChooserLabel {
  background: #c6c7c8 !important;
  color: #525353 !important;
  border: none;
}
.ui.label.matChooserLabel:before {
  border: none;
}
.ui.label.matChooserLabel:hover {
  color: #363737 !important;
  background: #c1c2c4 !important;
}
.ui.toggle.checkbox.inverted label:before,
.ui.toggle.checkbox.inverted input:focus ~ label:before {
  background: rgba(255, 255, 255, 0.25);
}
.ui.toggle.checkbox.inverted input:checked ~ label:before,
.ui.toggle.checkbox.inverted input:checked:focus ~ label:before {
  background: #008f27 !important;
}
.ui.toggle.checkbox.inverted input ~ label,
.ui.toggle.checkbox.inverted input:focus ~ label {
  color: white !important;
}
td > .ui.fitted.toggle.checkbox {
  margin: 6px 0px;
}
.ui.menu.chooserTopMenu {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.ui.menu.chooserTopMenu.inverted .item:before {
  background: rgba(255, 255, 255, 0.1) !important;
}
.ui.menu.chooserTopMenu .resChoosDrop.ui.dropdown .text,
.ui.menu.chooserTopMenu .resChoosDrop.ui.dropdown .icon {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.ui.menu.chooserTopMenu .resChoosDrop.ui.dropdown .menu.blue {
  background-color: #004594 !important;
}
.ui.menu.chooserTopMenu .resChoosDrop.ui.dropdown .menu.blue > .item {
  color: white !important;
}
.ui.menu.chooserTopMenu .resChoosDrop.ui.dropdown .menu.grey {
  background-color: #e0e1e2 !important;
}
.ui.menu.chooserTopMenu .resChoosDrop.ui.dropdown .menu > .item {
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 0.92857143em 1.14285714em !important;
}
.mapTab {
  min-height: 250px;
}
.litecodes {
  margin-bottom: 20px;
}
.litecodes .litecodes-header {
  font-weight: bold;
  font-size: 110%;
  margin-bottom: 10px;
}
.litecodes .codeCode {
  font-family: Consolas, "Andale Mono", Monaco, "Bitstream Vera Sans Mono", "DejaVu Sans Mono", monospace;
}
.litecodes:last-child {
  margin-bottom: 0;
}
.ui.icon.header.connectionError {
  color: black;
}
.ui.icon.header.connectionError .sub.header {
  margin: 10px;
}
.infoMessage {
  font-weight: lighter;
}
.code {
  display: flex;
}
.codeName {
  font-weight: bold;
  margin-right: 10px;
  min-width: 150px;
}
.imageInner.iiGroup.picon.picon-group {
  font-size: 24px;
  overflow: hidden;
  text-shadow: none;
  font-weight: normal;
  background: #004594;
  color: #ffffff;
}
.imageInner.iiGroup.picon.picon-group:before {
  margin: 0;
}
.field.disabled > label,
.disabled.field {
  opacity: 1 !important;
}
.ui.tab.centered {
  padding-top: 30px;
  text-align: center;
  min-height: 100px;
}
.ui.tab.centered .loader {
  margin-top: -10px;
}
.ui.disabled.input,
.ui.disabled.search {
  opacity: 0.7 !important;
}
.mergeDropdowns .ui.fluid.dropdown {
  min-width: 200px;
  border-radius: 2px 0 0 2px;
}
.mergeDropdowns .ui.fluid.dropdown .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.mergeDropdowns .ui.fluid.dropdown:first-child {
  border-radius: 2px 0 0 2px;
}
.mergeDropdowns .ui.fluid.dropdown:last-child {
  border-radius: 0 2px 2px 0;
  border-left: none;
}
.buttonLine {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  box-shadow: 0px 0px 10px 0px #0000007a;
  border-bottom: 1px solid #0000005a;
  z-index: 1001;
  flex-wrap: wrap;
}
.buttonLine .buttonBlock {
  display: flex;
}
.buttonLine .buttonBlock .ui.active.inline.loader {
  align-self: center;
  margin: 0 5px;
}
.buttonLine .buttonBlock .buttons {
  margin-right: 8px;
}
.buttonLine .buttonBlock .buttons:last-child {
  margin-right: 0;
}
.dayViewPage .DateInput {
  width: initial;
  min-width: 200px;
}
td.CalendarDay {
  vertical-align: middle;
}
.DateInput_input {
  padding: 6px 10px 4px;
  font-size: 14px;
  font-family: system-ui;
  font-weight: normal;
  text-align: center;
}
li.DayPicker_weekHeader_li {
  margin-top: -4px;
}
div#mapPage {
  display: flex;
  flex-direction: column;
  height: 100%;
}
div#map_map {
  flex: 1;
}
.languages {
  text-align: right;
  /* font-size: 200%; */
  padding: 0;
}
.languages .flag {
  cursor: pointer;
  padding: 0 10px;
  display: inline-block;
}
.rent .resc_image {
  background: #e5d734;
}
.rent .resc_image i.icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}
.resc_image.resc_group.picon {
  font-size: 80px;
  background: #004594;
  color: #ffffff;
}
.imageInner.rentalResource,
.labelImage.rentalResource {
  background-color: #e5d734 !important;
  color: #333333 !important;
  text-shadow: none;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
}
#calEditPage > .ui.placeholder {
  margin: 14px;
}
#calEditPage .mapTab {
  min-height: 250px;
}
#calEditPage .calendar_wrapper {
  min-height: 190px;
}
.editTabs {
  margin: 14px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.editTabs .ui.menu {
  overflow: auto;
}
.editTabs .mapTab {
  flex: 1;
  padding: 0;
}
.editTabs .mapTab .leaflet-container {
  height: 100%;
}
.ui.compact.icon.button.bpolitebutton {
  padding: 3.1px 2.6px 7px;
}
.ui.compact.icon.button.bpolitebutton i {
  font-size: 19px;
}
.icon.picon:before {
  margin: 0;
}
.SingleDatePicker_picker__portal {
  z-index: 4444;
}
.reseticon {
  background-size: contain !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.awesome-marker i.icon[class*=" picon-"]:before {
  margin: 0;
}
.ui.modal.jobEditModal > .ui.menu,
.ui.modal.projectEditModal > .ui.menu,
.ui.modal.agfModal > .ui.menu {
  margin-bottom: 0;
}
.ui.modal.jobEditModal > .content,
.ui.modal.projectEditModal > .content,
.ui.modal.agfModal > .content {
  padding-top: 0;
}
.ui.modal.jobEditModal .ui.bottom.attached.segment,
.ui.modal.projectEditModal .ui.bottom.attached.segment,
.ui.modal.agfModal .ui.bottom.attached.segment {
  flex: 1;
}
.ui.modal.jobEditModal .mapTab,
.ui.modal.projectEditModal .mapTab,
.ui.modal.agfModal .mapTab {
  position: relative;
}
.ui.modal.jobEditModal .mapTab .leaflet-container,
.ui.modal.projectEditModal .mapTab .leaflet-container,
.ui.modal.agfModal .mapTab .leaflet-container {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.processChooserWrapper,
.jobChooserWrapper {
  display: flex;
  user-select: none;
  flex-wrap: wrap;
}
.processChooserWrapper .processItem,
.jobChooserWrapper .processItem {
  /* padding: 5px; */
  display: flex;
  flex: 1;
  margin-bottom: 10px;
  padding: 10px 10px 8px 10px;
  flex-direction: column;
  align-items: center;
  min-width: 20%;
  box-sizing: border-box;
  cursor: pointer;
}
.processChooserWrapper .processItem.chosen,
.jobChooserWrapper .processItem.chosen {
  background: #eee;
  font-weight: bold;
}
.processChooserWrapper .processItem:hover .processItemName,
.jobChooserWrapper .processItem:hover .processItemName {
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 2px;
}
.processChooserWrapper .processItem:hover .processItemBox,
.jobChooserWrapper .processItem:hover .processItemBox {
  box-shadow: rgba(0, 0, 0, 0.6) 1px 1px 3px 0px inset !important;
  line-height: 46px;
}
.processChooserWrapper .processItem .processItemName,
.jobChooserWrapper .processItem .processItemName {
  font-weight: lighter;
  transition: text-shadow 0.1s ease-in-out;
}
.processChooserWrapper .processItem .processItemOwner,
.jobChooserWrapper .processItem .processItemOwner {
  font-style: italic;
  text-align: center;
}
.processChooserWrapper .processItem .processItemBox,
.jobChooserWrapper .processItem .processItemBox {
  text-align: center;
  transition: box-shadow 0.1s ease-in-out, text-shadow 0.1s ease-in-out;
  width: 50px;
  box-shadow: rgba(0, 0, 0, 0.5) -1px -1px 3px 0px inset !important;
  line-height: 47px;
  height: 50px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 4px;
}
.smallFieldWrapper {
  display: flex;
}
.smallFieldWrapper .field {
  flex: 1;
}
.smallFieldWrapper .button {
  align-self: flex-start;
  margin-left: 10px;
}
.smallButtonWrapper {
  text-align: right;
}
.questionContainer .ui.attached.segment:last-child:not(.animating) {
  border-bottom: none;
}
.sortableHelper {
  z-index: 2000;
}
#questionWrapper {
  padding: 1.5rem;
}
.resourceChooserWrapper,
.requirementChooserWrapper,
.agfWrapper {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: auto;
  padding: 1.5rem;
}
.codesWrapper {
  padding: 1.5rem;
}
.ui.secondary.processChooserMenu.menu {
  padding: 8px;
  overflow: auto;
  border-bottom: 1px solid #dededf;
  margin: 0;
}
.ui.secondary.processChooserMenu.menu a.active.item {
  background-color: #333;
  color: rgba(255, 255, 255, 0.95);
}
.resc_classwrapper {
  margin-bottom: 15px;
  border-bottom: 1px solid #666;
}
.resc_classwrapper:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.resc_wrapper {
  display: grid;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px 10px;
}
.compactmode .resc_wrapper {
  display: flex;
  max-width: 100%;
  overflow: auto;
}
.popupLine {
  margin-bottom: 4px;
}
.popupLine:last-child {
  margin-bottom: 0;
}
.popupLine .popupQuestion {
  font-weight: bolder;
}
.popupLine .popupAnswer {
  text-align: right;
}
.rentalresource.popup {
  width: 400px;
}
.rentalEdit {
  font-size: 15px;
  background: black;
  color: white;
  min-height: 20px;
  overflow: hidden;
  line-height: 20px;
}
.rentalEdit:hover {
  background: #333;
}
.bigM {
  flex: 1;
  background: #e5d734;
  color: #333333;
  line-height: 76px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}
.resc_image.rental {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.rentalEdit .icon {
  margin: 0;
}
.flexgroup {
  justify-content: space-between;
  display: flex;
}
.resc_classname {
  text-transform: uppercase;
  color: #444;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.resc_res {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 20%;
  box-sizing: border-box;
  padding: 10px 10px;
  cursor: pointer;
}
.resc_res.marked {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.compactmode .resc_res {
  min-width: 110px;
  margin: 0 5px 5px 0;
}
.compactmode .resc_res:last-child {
  margin-right: 0;
}
.resc_image {
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 3px 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
}
.resc_image:not(.reqdata) {
  line-height: 47px;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  margin-bottom: 4px;
  line-height: 100px;
}
.resc_image.reqdata {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #444;
}
.resc_image.reqdata .reqdata_count {
  text-align: center;
  color: black;
  text-shadow: 0px 0px 3px white;
  background-color: rgba(255, 255, 255, 0.6);
  font-weight: bold;
  font-size: 40px;
  line-height: 65px;
  flex: 1;
}
.resc_image.reqdata .reqdata_buttons {
  display: flex;
  border-top: 1px solid #999;
}
.resc_image.reqdata .reqdata_buttons .reqdata_icon {
  flex: 1;
  color: white;
  text-align: center;
  min-height: 30px;
  line-height: 30px;
  background: rgba(20, 20, 20, 0.9);
  border-right: 1px solid #999;
}
.resc_image.reqdata .reqdata_buttons .reqdata_icon i {
  padding: 0;
  margin: 0;
}
.resc_image.reqdata .reqdata_buttons .reqdata_icon:last-child {
  border-right: none;
}
.resc_image.reqdata .reqdata_buttons .reqdata_icon:hover {
  background: rgba(50, 50, 50, 0.9);
}
.resc_image .resc_image_background {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}
.nagel .resc_image {
  background-size: 80%;
  background-repeat: no-repeat;
}
.resc_image.undivided {
  font-size: 70px;
}
.resc_image.picon {
  font-size: 70px;
}
.resc_image.picon:before {
  margin: 0;
}
.resc_image.absenceBorder {
  border-width: 8px;
}
.unavailable .resc_image .resc_image_background {
  filter: grayscale(1);
  opacity: 0.8;
}
.unavailable .resc_image i {
  opacity: 1;
  font-size: 80px;
  margin: 0;
  line-height: 100px;
}
.ui.popup.requestcomment .content {
  display: flex;
}
.ui.popup.requestcomment .content button {
  margin-left: 5px;
}
.resc_name {
  font-weight: lighter;
  text-align: center;
  max-width: 150px;
}
.nagel .resc_name {
  font-weight: bolder;
}
.resc_name.subname {
  font-size: 90%;
}
.unavailable .resc_name {
  font-style: italic;
}
.resourceChooserModal.ui.modal > .content,
.requirementChooserModal.ui.modal > .content,
.agfModal.ui.modal > .content {
  padding: 0;
}
.resourceChooserModal div.ui.menu,
.requirementChooserModal div.ui.menu,
.agfModal div.ui.menu {
  margin-bottom: 0;
}
.resMapDetail {
  grid-column: 1 / 6;
  border: 1px solid #666;
  border-radius: 5px;
  display: flex;
  position: relative;
}
.compactmode .resMapDetail {
  border-bottom: none;
}
.resMapDetail .resMapMarker {
  transition: left 500ms ease-in-out;
  position: absolute;
  width: 40px;
  height: 40px;
  background: white;
  border: 1px solid #666;
  margin-left: -20px;
  box-shadow: 1px -1px 8px 0px rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 4px;
  transform: rotate(45deg) skew(15deg, 15deg);
  top: -17px;
}
.resMapDetail .resMap {
  flex: 1;
  background: #aaa;
  min-height: 300px;
  z-index: 2;
}
.resMapDetail .resMap .leaflet-container {
  height: 100%;
}
.resMapDetail .details {
  /*max-width: 33%;*/
  padding: 10px;
  z-index: 2;
  background: white;
  border-left: 1px solid #666;
}
.awesome-marker i.icon {
  margin: 0;
  margin-top: 8px;
}
.nagelLogo {
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 2.5rem;
}
.nagelRow {
  display: flex;
  margin: 10px 30%;
  justify-content: space-between;
}
.nagelH {
  font-weight: bolder;
}
.nagelD {
  font-weight: lighter;
}
.button.collision {
  animation-name: redden;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes redden {
  10% {
    background-color: #e0e1e2;
    color: rgba(0, 0, 0, 0.6);
  }
  40% {
    background-color: red;
    color: rgba(255, 255, 255, 0.9);
  }
  60% {
    background-color: red;
    color: rgba(255, 255, 255, 0.9);
  }
  90% {
    background-color: #e0e1e2;
    color: rgba(0, 0, 0, 0.6);
  }
}
.ui.modal.collisionModal .collisionDate {
  font-size: 120%;
  font-weight: bold;
  margin: 16px 0 5px 0;
}
.ui.modal.collisionModal .resourceName {
  font-weight: bold;
}
.ui.modal.collisionModal .project {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.ui.modal.collisionModal .accept,
.ui.modal.collisionModal .delete {
  min-width: 40px;
  /* text-align: center; */
}
.ui.modal.collisionModal .time {
  min-width: 117px;
  text-align: center;
}
.ui.modal.collisionModal button.ui.compact.icon.button {
  margin: 0;
}
.ui.modal.collisionModal .accept {
  text-align: left;
}
.ui.modal.collisionModal .delete {
  text-align: center;
}
.ui.modal.collisionModal .collisionHandler {
  margin-bottom: 10px;
}
.ui.modal.collisionModal > .content {
  padding-top: 0px;
  padding-bottom: 14px;
  max-height: 80vh;
  overflow: auto;
}
.ui.modal.collisionModal .ui.menu {
  margin-bottom: 0;
}
.ui.modal.collisionModal .collisionNothing {
  padding: 33px 0 20px;
}
@media (max-width: 1049px) {
  .buttonLine .zoomButtonBlock {
    order: 1;
  }
  .buttonLine .boardButtonBlock {
    order: 3;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  .buttonLine .exportButtonBlock {
    order: 2;
  }
  .buttonLine .actionButtonBlock {
    order: 2;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .buttonLine .zoomButtonBlock {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
  .buttonLine .exportButtonBlock {
    justify-content: center;
    width: 100%;
  }
}
.calendar_wrapper::-webkit-scrollbar {
  display: none;
}
.calendar_wrapper {
  scrollbar-width: none;
}
.text.selectorContent {
  display: flex;
  align-items: center;
}
.selName {
  flex: 1;
}
.icon.addbutton {
  display: none;
  margin-left: 5px;
  color: #999;
  margin-right: -3px;
}
.editline .project_name button.jnumberbutton {
  /* align-self: stretch; */
  margin: 0 5px 0 0 !important;
}
.icon.addbutton:hover {
  color: black;
}
.xlabeled .menu > div:hover .icon.addbutton {
  display: block;
}
.ui.floating.xlabeled.dropdown .menu {
  left: auto;
  right: 0;
  max-height: 80vh;
  overflow: auto;
}
.ui.xlabeled.icon.button,
.ui.xlabeled.icon.buttons .button {
  position: relative;
  padding-left: 4.07142857em !important;
  padding-right: 1.5em !important;
}
.ui.xlabeled.icon.button > .icon,
.ui.xlabeled.icon.buttons > .button > .icon {
  position: absolute;
  height: 100%;
  line-height: 1;
  border-radius: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  text-align: center;
  margin: 0;
  width: 2.57142857em;
  background-color: rgba(0, 0, 0, 0.05);
  color: "";
  box-shadow: inset -1px 0 0 0 transparent;
  top: 0;
  left: 0;
}
.ui.xlabeled.icon.button > .icon:after,
.ui.xlabeled.icon.button > .icon:before,
.ui.xlabeled.icon.buttons > .button > .icon:after,
.ui.xlabeled.icon.buttons > .button > .icon:before {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ui.button.onlyone {
  padding-left: 4.07142857em !important;
  padding-right: 1.5em !important;
  padding: 0.78571429em;
}
.ui.button.onlyone a.ui.label {
  display: inline-block !important;
  border-color: transparent !important;
  background-color: initial;
  box-shadow: none !important;
  cursor: text;
  position: relative;
  left: 1px;
  pointer-events: none;
  z-index: 3;
  padding: initial !important;
  margin-left: 0 !important;
  mouse-events: none;
}
.ui.button.onlyone.active a.ui.label {
  color: white !important;
}
.ui.button.onlyone i.delete {
  display: none;
}
div.calPage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  user-select: none;
}
.hxTruckCount {
  position: absolute;
  right: 3px;
  bottom: 0;
  z-index: 2;
  font-weight: bold;
  color: #666;
}
.outerProjectName {
  position: absolute;
  top: -20px;
  color: white;
  left: 0;
  right: 0;
  text-align: center;
}
div.calendar_wrapper {
  overflow: auto;
}
#calPage div.calendar_wrapper {
  flex: 1;
}
#calEditPage div.calendar_wrapper {
  border-bottom: 1px solid #aaa;
}
.ebox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.5);
}
.ebox .icon {
  margin: 0;
  margin-top: -2px;
}
.mpinfo_csname {
  margin-top: 8px;
  font-weight: bold;
}
.mpinfo_csname:first-child {
  margin-top: 0;
}
.mpinfo_line {
  display: flex;
}
.mpinfo_line.mpinfo_milling_type {
  color: #048020;
}
.mpinfo_line .mpinfo_start {
  min-width: 90px;
  font-style: italic;
}
.buttonInner {
  display: flex;
  justify-content: flex-end;
}
.buttonInner .button {
  align-self: center;
}
.buttonInner .button.lesser {
  background: rgba(224, 225, 226, 0.6);
}
.buttonInner .button.lesser:hover {
  background: rgba(224, 225, 226, 0.5);
}
.buttonInner .button:last-child {
  margin: 0;
}
.cluster_box {
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.22);
  height: 100%;
  width: 100%;
  background-color: #ebebeb;
  color: black;
  display: flex;
  z-index: 200;
  flex-direction: column;
  overflow: hidden;
}
.cluster_box.absence_cluster_box .itemBoxCenter {
  align-content: center;
  justify-content: space-around;
  padding: 4px;
  font-weight: bolder;
  font-size: 16px;
}
.cluster_box .itemBoxTop {
  min-height: 21px;
  max-height: 21px;
  overflow: hidden;
  font-size: 15px;
  line-height: 21px;
  display: flex;
}
.cluster_box .itemBoxTop .itemBoxTopTime {
  text-align: center;
  padding: 0 4px;
  font-size: 14px;
  font-weight: lighter;
  border-right: 1px solid rgba(0, 0, 0, 0.6);
}
.cluster_box .itemBoxTop .itemBoxTopName {
  padding: 0 6px;
  font-weight: bold;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cluster_box .itemBoxCenter,
.cluster_box .itemBoxTop {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}
.cluster_box .itemBoxCenter {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  line-height: 12px;
  padding: 0 0 4px 4px;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
}
.cluster_box .itemBoxCenter .clusterDetail {
  margin: 4px 4px 0 0;
}
.cluster_box .itemBoxBottomGrouper {
  min-height: 16px;
  max-height: 16px;
  display: flex;
  justify-content: space-between;
}
.cluster_box .itemBoxBottomGrouper .itemBoxBottomTime {
  padding: 0 4px;
  font-size: 14px;
  font-weight: lighter;
  border-right: 1px solid rgba(0, 0, 0, 0.6);
  line-height: 16px;
}
.cluster_box .itemBoxBottomGrouper .itemBoxBottomButtons {
  display: flex;
  flex: 1;
}
.cluster_box .itemBoxBottomGrouper .itemBoxBottomButtons .itemBoxBottom {
  flex: 1;
  text-align: center;
  border-left: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  flex: 1 1;
  line-height: 16px;
  cursor: pointer;
  overflow: hidden;
  display: block;
  height: 100%;
}
.cluster_box .itemBoxBottomGrouper .itemBoxBottomButtons .itemBoxBottom:hover {
  background: #dbdbdb;
}
.cluster_box .itemBoxBottomGrouper .itemBoxBottomButtons .itemBoxBottom:first-child {
  border: none;
}
.cluster_box_wrapper {
  padding: 4px;
}
.cluster_box_planning {
  max-height: 100px;
}
.restext_box {
  color: white;
  font-size: 10px;
  padding: 3px;
  line-height: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 0px;
}
.processItemBox.picon:before,
.processSwatch.picon:before {
  margin: 0;
}
.picon-street:before {
  position: relative;
  left: -0.05em;
}
.nameInner {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 6px;
  flex: 1;
}
.project_process .nameInner {
  padding: 0px 20px;
}
.resource_dl_wrapper {
  background-color: #20538d;
  border-right: 1px solid #c7d0dc;
  max-width: 200px;
  overflow: hidden;
  min-width: 120px;
  padding: 8px;
  position: relative;
}
.resource_dl_wrapper .background {
  position: absolute;
  background-size: cover;
  background-position: center center;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
}
.resource_dl_wrapper .txt {
  z-index: 3;
  text-overflow: ellipsis;
  position: relative;
  color: #ffffff;
  text-shadow: 0 1px 0 #000000;
  font-weight: bold;
  white-space: nowrap;
  opacity: 1;
}
.resmode .project_name {
  background: rgba(23, 59, 100, 0.9);
}
.project_name,
.resource_name {
  max-width: 350px;
  padding: 0 4px;
  color: #ffffff;
  background: rgba(32, 83, 141, 0.9);
  text-shadow: 0 1px 0 #000000;
  font-weight: bold;
  align-items: center;
  display: flex;
  border-right: 1px solid #c7d0dc;
}
.project_name.extended,
.resource_name.extended {
  max-width: 450px;
}
.project_name .pedit_pname,
.resource_name .pedit_pname {
  margin-left: 1px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project_resource .project_name,
.project_resource .resource_name,
.project_requirement .project_name,
.project_requirement .resource_name {
  padding-left: 24px;
  font-size: 95%;
}
.resource_dl_wrapper .project_name,
.resource_dl_wrapper .resource_name {
  background: initial;
  border-right: initial;
  padding: 4px 4px;
}
.project_name.marked,
.resource_name.marked {
  background: rgba(64, 133, 212, 0.9);
}
.project_resource.groupline .project_name,
.project_resource.groupline .resource_name {
  padding-left: 17px;
  font-weight: lighter;
}
.resource_main.groupline .project_name,
.resource_main.groupline .resource_name {
  padding-left: 30px;
  font-weight: lighter;
}
.project_process .project_name,
.project_process .resource_name,
.project_resource.groupline .project_name,
.project_resource.groupline .resource_name,
.resource_main.groupline .project_name,
.resource_main.groupline .resource_name {
  font-size: 90%;
  line-height: 20px;
  background: rgba(46, 120, 204, 0.9);
}
.project_process .project_name.marked,
.project_process .resource_name.marked,
.project_resource.groupline .project_name.marked,
.project_resource.groupline .resource_name.marked,
.resource_main.groupline .project_name.marked,
.resource_main.groupline .resource_name.marked {
  background: rgba(105, 159, 221, 0.9);
}
.project_name .processSwatch,
.resource_name .processSwatch {
  align-self: stretch;
  margin: 5px 5px 5px 0px;
  width: 25px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  user-select: none;
  cursor: pointer;
  font-size: 18px;
  text-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 2px;
}
.editline .project_name,
.editline .resource_name {
  padding: 0 5px;
}
.editline .project_name button,
.editline .resource_name button {
  margin: 0 0 0 5px !important;
}
.editline .project_name .input,
.editline .resource_name .input {
  flex: 1;
}
.lineHeader_name {
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  padding-left: 10px;
  cursor: pointer;
}
.lineHeader_head,
.lineHeader_body {
  background: #b1cff1;
}
.project_body,
.resource_body:not(.hiddenline),
.lineHeader_name,
.lineHeader_body {
  border-bottom: 1px solid #4f5964;
}
.project_body.clusterborder,
.resource_body:not(.hiddenline).clusterborder,
.lineHeader_name.clusterborder,
.lineHeader_body.clusterborder {
  border-bottom: 1px solid #e0e3e6;
}
.whitemarker_box {
  background: rgba(0, 0, 0, 0.3);
}
.request_box {
  background: #eeeeee;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5) inset;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.request_box.moved {
  background: #cc1120 !important;
}
.request_box.marked {
  background: #bbb;
}
.request_box .res_perf_data {
  height: 11px;
  margin-top: 4px;
}
.project_body.marked {
  background: rgba(64, 133, 212, 0.5);
}
.process_box {
  background: #8cb9ed;
}
.process_box.mp_box {
  text-align: center;
  font-size: 90%;
  overflow: hidden;
}
.process_box.night {
  background: #606060;
}
.process_box.marked {
  background: #b9d4f4;
}
.process_box.hovered {
  background: #cc1120 !important;
}
.process_box.moved,
.process_box.editmarked {
  background: #cc1120 !important;
}
.standard_box {
  background: #4085d4;
}
.standard_box.night {
  background: #404040;
}
.standard_box.bpoplanning {
  background: #404040;
}
.standard_box.greyDay {
  background: #3f5774;
}
.groupline .standard_box {
  background: #8cb9ed;
}
.resmode .project_main .standard_box {
  background: #215592;
}
.resmode .project_main .standard_box.night {
  background: #303030;
}
.standard_box.react-draggable-dragging {
  z-index: 100;
}
.standard_box.hovered {
  background: #cc1120 !important;
}
.standard_box.moved,
.standard_box.editmarked {
  background: #cc1120 !important;
}
.standard_box.copyOrigin {
  background: #f38600 !important;
}
.standard_box.marked {
  background: #93bae6 !important;
}
.standard_box.collision {
  background: #cc1120;
}
.vertical_line {
  border-left: 1px solid #c7d0dc;
}
.vertical_line.holiday {
  background: #ccc;
  border-left: 1px solid #d5e4f6;
}
.imageInner {
  height: 24px;
  width: 26px;
  border-radius: 0.28571429rem;
  background-size: cover;
  background-position: 50% 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.resource_dl_wrapper .imageInner {
  height: 50px;
  width: 50px;
  border-radius: 0.5571429rem;
}
.calheader_month {
  color: black;
  display: flex;
  border-right: 1px solid #aaa;
  justify-content: space-between;
  font-weight: bold;
  background: white;
  text-shadow: 0 1px 0 white;
}
.calheader_day {
  background: #2066b6;
  text-shadow: 0 1px 0 #000000;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border-left: 1px solid #c7d0dc;
  padding: 0 3px;
  border-bottom: 1px solid #4f5964;
}
.calheader_day.holiday {
  background: #184e8b;
  color: #ffffff;
  text-shadow: 0 1px 0 #000000;
}
.cal_head {
  background: #1e538f;
  border-bottom: 1px solid #7f8b9a;
  border-right: 1px solid #c7d0dc;
  color: transparent;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
}
.truck-error {
  font-size: 13px;
  font-weight: 400;
  color: #cc1120;
  font-style: normal;
}
#dayViewPage .labelContainer .resourceLabel.related {
  flex: 0 1 80%;
}
#dayViewPage .labelContainer .resourceLabel.related.related-separate {
  margin-bottom: 15px;
}
#dayViewPage .labelContainer .resourceLabel.related .labelText {
  max-width: 1000px;
}
.resource_main .resource_name .imageInnerPopup {
  /*transition: transform .1s;*/
  background-color: rgba(32, 83, 141);
  position: absolute;
  z-index: 100;
  width: 30px;
  height: 30px;
  z-index: 1000;
  bottom: 78px;
  left: 110px;
  border-radius: 1px;
  background-position: 50% 50%;
  background-size: cover;
  opacity: 0;
}
.resource_main .resource_name .imageInner:hover > .imageInnerPopup {
  opacity: 1;
  transform: scale(6);
}
.delete-resources-icon-custom {
  padding: 4px 3px 3px!important;
}
.delete-resources-icon-custom .group {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  width: 17px!important;
  height: 17px!important;
}
.delete-resources-icon-custom .group:before {
  font-size: 10px;
  position: relative;
  top: 2px;
  left: 0px;
}
.delete-resources-icon-custom .group:after {
  content: "";
  width: 1px;
  height: 17px;
  background-color: rgba(0, 0, 0, 0.6);
  display: block;
  position: absolute;
  top: -1px;
  right: 7px;
  rotate: 135deg;
}
.materialLabelContainer {
  width: 400px;
  position: relative;
  overflow: hidden;
  height: 14px;
}
@media (max-width: 1250px) {
  .materialLabelContainer {
    width: 200px;
  }
}
.materialLabelInner {
  position: absolute;
  left: 10px;
  text-align: left;
}
.materialLabelContainer:hover .materialLabelInner {
  animation: materialLabelScroll 8s linear infinite;
}
.matChooserName.materialLabelDots {
  position: relative;
}
.matChooserName.materialLabelDots:after {
  content: "...";
  position: absolute;
  top: 11px;
  right: 11px;
}
.matChooserName.materialLabelDots:hover:after {
  content: "";
}
@keyframes materialLabelScroll {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-250px);
  }
  100% {
    transform: translateX(0%);
  }
}
/* Roller Concepts */
.roller_add_button button {
  height: 38px;
}
.roller_add_button .ui.labeled.icon.button {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}
.roller_add_button .ui.labeled.icon.button i.icon:before {
  font-size: 16px;
}
.roller_performance_single_result {
  display: flex;
  height: 38px;
  align-items: center;
}
.roller_tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}
.roller_tooltip .icon.tooltip_icon {
  border: 1px solid #000000;
  width: 15px;
  height: 15px;
  line-height: 13px;
  font-size: 8px;
  font-style: italic;
  border-radius: 20px;
}
.roller_tooltip .roller_tooltip_top {
  white-space: normal;
  width: 400px;
  top: -20px;
  left: 50%;
  transform: translate(-30%, -100%);
  padding: 10px 20px;
  color: #ffffff;
  background-color: #004594;
  font-weight: normal;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
  line-height: 1.5;
}
.roller_tooltip .roller_tooltip_top h4 {
  margin-bottom: 10px;
}
.roller_tooltip .roller_tooltip_top .roller_tooltip_formula {
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.roller_tooltip:hover .roller_tooltip_top {
  display: block;
}
.roller_tooltip .roller_tooltip_top i {
  position: absolute;
  top: 100%;
  left: 30%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
}
.roller_tooltip .roller_tooltip_top i::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #004594;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
.roller_tooltip_request .roller_tooltip_top {
  width: 150px;
  left: 50%;
  padding: 8px 10px;
  text-align: center;
}
.NSPN-table {
  margin-top: 0 !important;
  margin-bottom: -2px !important;
}
.roller_remove_button {
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
}
.roller_remove_button button {
  padding: 8px 10px 6px !important;
}
.roller-concept-NSPN-checkbox .field {
  display: none;
}
.roller-concept-NSPN-checkbox .onoffswitch {
  position: relative;
  width: 56px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.roller-concept-NSPN-checkbox .onoffswitch-checkbox {
  display: none;
}
.roller-concept-NSPN-checkbox .onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  position: relative;
}
.roller-concept-NSPN-checkbox .onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.roller-concept-NSPN-checkbox .onoffswitch-inner:before,
.roller-concept-NSPN-checkbox .onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 22px;
  padding: 0;
  line-height: 22px;
  font-size: 12px;
  color: black;
  font-weight: bold;
  box-sizing: border-box;
}
.roller-concept-NSPN-checkbox .onoffswitch-inner:before {
  content: "";
  padding-left: 6px;
  background-color: #004594;
  color: #FFFFFF;
}
.roller-concept-NSPN-checkbox .onoffswitch-inner:after {
  content: "";
  padding-right: 6px;
  background-color: #d4d4d5;
  color: #FFFFFF;
  text-align: right;
}
.roller-concept-NSPN-checkbox .onoffswitch-switch {
  display: block;
  width: 12px;
  margin: 5px;
  height: 12px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.roller-concept-NSPN-checkbox .onoffswitch.checked > .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.roller-concept-NSPN-checkbox .onoffswitch.checked > .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
.roller-concept-NSPN-input,
.carbon-concept-NSPN-input {
  position: relative;
  padding-right: 30px;
}
.roller-concept-NSPN-input input,
.carbon-concept-NSPN-input input {
  width: 110px;
}
.roller-concept-NSPN-input .NSPN-unit,
.carbon-concept-NSPN-input .NSPN-unit {
  position: absolute;
  top: 10px;
  left: 120px;
}
.roller_block_one_wrap {
  display: flex;
  flex-direction: column;
}
.roller_block_one_wrap .roller_single_takt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 150px);
}
.roller_block_one_wrap .roller_block_one {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.roller_block_one_wrap .roller_block_one_field {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.roller_block_one_wrap .roller_block_one_field .roller_block_one_field_label {
  margin-right: 14px;
}
.roller_block_two_wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}
.roller_block_two_wrap > div {
  width: calc(25% - 15px);
  margin-bottom: 20px;
}
@media (max-width: 1700px) {
  .roller_block_two_wrap > div {
    width: calc(33% - 10px);
  }
}
@media (max-width: 1300px) {
  .roller_block_two_wrap > div {
    width: calc(50% - 10px);
  }
}
@media (max-width: 900px) {
  .roller_block_two_wrap > div {
    width: 100%;
  }
}
.roller_block_two_wrap > div .ui.table tr td {
  vertical-align: middle;
}
.roller_block_two_wrap > div .ui.table thead tr:first-child > th:first-child {
  vertical-align: middle;
  height: 67px;
}
.roller_block_two_wrap .roller_group_head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.roller_block_two_wrap .roller_group_head .roller_paver_needed_label {
  font-size: 12px;
  width: 100%;
}
.roller_block_two_wrap .roller_remove_button {
  vertical-align: middle !important;
}
.no-border {
  border: 0px !important;
}
.roller_block_two_wrap sup,
.roller_block_one_wrap sup {
  font-size: 11px;
  top: -2.5px;
}
.roller-resources-calculator td {
  padding: 6px !important;
  font-size: 12px;
  vertical-align: middle;
}
.roller-resources-calculator input {
  width: 53px;
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.roller-resources-calculator .NSPN-unit {
  left: 55px;
}
.roller-resources-calculator sup {
  top: -1.5px;
}
.roller_request_button_wrap {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.roller_request_button_wrap .roller_request_failure_message {
  color: red;
}
.roller_request_button_wrap .roller_request_success_message {
  color: green;
}
.res_perf_data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  letter-spacing: -0.5px;
}
.res_perf_data.res_txt_red {
  color: #db2828;
}
.res_perf_data.res_txt_green {
  color: #03791f;
}
.req_perf_data {
  vertical-align: middle;
  display: flex;
  padding: 0 3px;
}
.hovered .res_perf_data.res_txt_red {
  color: #000000;
}
.hovered .res_perf_data.res_txt_green {
  color: #000000;
}
.standard_box.night .res_perf_data {
  color: #ffffff;
}
.requestName .requestPopupTrigger {
  color: #fff;
  left: auto;
  position: absolute;
  right: 0;
}
.roller_request_filter_radio .ui.toggle.checkbox.inverted input ~ label,
.roller_request_filter_radio .ui.toggle.checkbox.inverted input:focus ~ label {
  color: #000000 !important;
}
.roller_request_filter_radio .ui.toggle.checkbox.inverted label:before,
.roller_request_filter_radio .ui.toggle.checkbox.inverted input:focus ~ label:before {
  border: 1px solid #d4d4d5;
}
.resc_res.roller_res_hide {
  display: none;
}
#dayViewPage .labelContainer .resourceLabel.related {
  flex: 0 1 80%;
}
#dayViewPage .labelContainer .resourceLabel.related.related-separate {
  margin-bottom: 15px;
}
#dayViewPage .labelContainer .resourceLabel.related .labelText {
  max-width: 1000px;
}
@media (max-width: 1049px) {
  .buttonLine .zoomButtonBlock {
    order: 1;
  }
  .buttonLine .boardButtonBlock {
    order: 3;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  .buttonLine .exportButtonBlock {
    order: 2;
  }
  .buttonLine .actionButtonBlock {
    order: 2;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .buttonLine .zoomButtonBlock {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
  .buttonLine .exportButtonBlock {
    justify-content: center;
    width: 100%;
  }
}
.ui.multiple.search.dropdown > input.search {
  margin: 0 0 0 10px!important;
  width: 0;
  transition: width 0.5s;
}
.ui.multiple.search.dropdown .sizer {
  display: none !important;
}
.exportButtonBlock .ui.multiple.active.search.dropdown > input.search {
  background-color: #ffffff !important;
  border: 1px solid #fff !important;
  width: 80px !important;
  height: 24px;
  margin-top: 2px !important;
}
.exportButtonBlock .ui.multiple.active.onlyone.search.dropdown > input.search {
  height: 18px;
  margin-top: 0 !important;
}
.groupPage {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: #dddde0;
}
.groupPage .buttonLine {
  background: white;
}
.groupPage .groupBody {
  flex: 1;
  overflow: auto;
  padding: 15px;
}
.groupPage .groupBody table,
.groupPage .groupBody .mockTable {
  box-shadow: 0px 0px 10px 0px #0000007a;
  border: 1px solid #0000006b;
  margin: 0 auto;
  max-width: 650px;
  border-radius: 5px;
  background-color: white;
}
.groupPage .groupBody table td,
.groupPage .groupBody .mockTable td {
  vertical-align: middle;
}
.groupPage .groupBody table.ui.table thead th,
.groupPage .groupBody .mockTable.ui.table thead th,
.groupPage .groupBody table .thead,
.groupPage .groupBody .mockTable .thead {
  background-color: #303030;
  color: white;
  border-bottom: 2px solid #ffffff69;
}
.groupPage .buttoncell {
  text-align: right !important;
}
.groupselect_wrapper {
  display: flex;
  margin: 15px auto;
  background: #fefefe;
  overflow: hidden;
  max-width: 700px;
  width: 100%;
  box-shadow: 0px 0px 10px 0px #0000007a;
  border: 1px solid #0000006b;
  border-radius: 4px;
  box-sizing: border-box;
}
.groupselect_wrapper .groupselect_left,
.groupselect_wrapper .groupselect_right {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.groupselect_wrapper .groupselect_right {
  border-left: 1px solid #aaa;
}
.groupselect_wrapper .emptygroup {
  color: #555;
  text-align: center;
  padding: 20px;
}
.groupselect_wrapper .groupselect_filter {
  min-height: 50px;
  background: #eee;
  border-bottom: 2px solid #868686;
}
.groupselect_wrapper .groupselect_filter .input {
  margin: 5px;
}
.groupselect_wrapper .groupselect_title {
  background-color: #303030;
  color: white;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid #ffffff69;
}
.groupselect_wrapper .groupselect_resources {
  overflow: auto;
}
.groupselect_wrapper .group_classHeader {
  font-weight: bold;
  border-bottom: 1px solid #aaa;
  padding: 4px 10px;
}
.groupselect_wrapper .groupchooser_resource {
  display: flex;
  margin: 10px;
  align-items: center;
}
.groupselect_wrapper .gc_image {
  width: 36px;
  height: 36px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 5px;
  border: 1px solid #aaa;
}
.groupselect_wrapper .gc_name {
  flex: 1;
  padding: 10px;
}
main {
  display: flex;
  flex-direction: column;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.titleWrap {
  display: flex;
}
img.logoimg {
  width: 100%;
}
.imgCol {
  max-width: 33%;
  overflow: hidden;
  margin-left: 1cm;
}
@media all {
  .page-break {
    display: none;
  }
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}
@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
@page {
  size: auto;
  margin: 20mm;
}
.ui.segment.qMessageSegment {
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.messageSegment {
  padding: 1rem;
}
.ui.modal.reportModal .content {
  padding: 0;
}
i.inverted.circular.icon.unknownIcon {
  background-color: orange !important;
  color: black !important;
}
i.inverted.circular.icon.missingIcon {
  background-color: #cc1120 !important;
  color: white !important;
}
i.inverted.circular.icon.presentIcon {
  background-color: green !important;
  color: white !important;
}
.qImageSegment {
  flex: 1;
  background-size: contain;
  border-top: 1px solid #dededf;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #333;
  box-shadow: inset 0 0 20px #0000007a;
}
.agfWrapper .qQuestionaryWrapper:last-child .qCategoryWrapper:last-child {
  margin-bottom: 0;
}
.qQuestionInfoIcon {
  float: right;
}
.qCategoryWrapper {
  margin-bottom: 15px;
}
.qImageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.ui.segments.qImageWrapper {
  margin: 0;
  page-break-inside: avoid;
}
.ui.segment.qMessageSegment {
  height: 350px;
}
.qImageSegment {
  padding: 0 !important;
}
.qImageImage {
  max-height: 400px;
}
.qQuestionaryMainTitle {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.processesWrapper {
  margin-top: 2px !important;
  margin-bottom: 12px !important;
}
.processReportItemSmall {
  margin-top: 0 !important;
  padding: 4px;
  padding-right: 8px;
  background: #efefef none;
  display: inline-block;
  font-weight: 500;
  font-size: 0.95em;
  margin-right: 8px;
}
.processReportItemSmall .processSwatch {
  height: 21px;
  width: 25px;
  font-size: 20px;
}
.qQuestionarySubTitle {
  font-weight: bold;
  margin-bottom: 3px;
}
span.qQuestionaryStand {
  font-style: italic;
  margin-right: 8px;
}
.qQuestionaryTitle {
  margin-top: 20px !important;
}
.agfWrapper .qQuestionaryWrapper:first-child .qQuestionaryTitle {
  margin-top: 0 !important;
}
.qColName {
  font-weight: normal !important;
}
th.qDefinitionTitle {
  box-shadow: inherit !important;
  background: #f9fafb !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border-radius: 0.28571429rem 0 0 0 !important;
  border-width: 1 !important;
}
div#reportPage {
  background: #dddde0;
  flex: 1;
}
@media print {
  div#reportPage {
    background: white;
  }
}
div#reportPage .reportWrapper {
  max-width: 1040px;
  margin: 20px auto;
  border-radius: 4px;
  /* padding: 60px 0; */
  overflow: hidden;
  box-shadow: 0 0 10px black;
  box-shadow: 0px 0px 10px 0px #0000007a;
  border: 1px solid #0000006b;
}
@media print {
  div#reportPage .reportWrapper {
    margin: 0 auto;
  }
}
div#reportPage .feedAdd {
  padding: 10px 30px 30px;
  background: #f0f0f0;
  border-top: 1px solid #00000029;
  box-shadow: 0 0 10px #0000004f;
}
div#reportPage .logbuch .messages.ui.feed {
  max-height: 400px;
  overflow: auto;
  padding: 20px 20px;
  margin: 0;
}
div#reportPage .chooserButtons {
  display: flex;
  border-bottom: 1px solid #ffffff69;
  box-shadow: 0 0 10px #0000004f;
  justify-content: space-between;
  padding: 5px;
  background: #303030;
  flex-wrap: wrap;
}
@media print {
  div#reportPage .chooserButtons {
    background: white;
    border-bottom: 2px solid #888;
  }
}
div#reportPage .buttonBlock {
  display: flex;
}
div#reportPage .nothingChosen {
  text-align: center;
  padding: 50px;
  font-weight: lighter;
  font-size: 20px;
}
div#reportPage .feedAdd .ui.horizontal.divider {
  margin-bottom: 21px;
  font-size: 19px;
}
div#reportPage div#report_buttons {
  background: white;
  position: fixed;
  width: 100%;
  top: 50px;
}
div#reportPage .reportWrapper {
  background: #fefefe;
  min-height: 400px;
}
div#reportPage .formgroup {
  display: flex;
  justify-content: space-between;
}
div#reportPage .apexcharts-datalabel-label {
  font-weight: bold;
  font-size: 22px;
}
div#reportPage .apexcharts-datalabel-value {
  font-weight: lighter;
  font-size: 35px;
}
div#reportPage .imageDetails {
  display: flex;
}
div#reportPage .image {
  flex: 3;
  min-height: 300px;
  background-size: cover;
  background-position: center center;
  box-shadow: -5px -5px 10px inset #00000066;
  border-right: 2px solid #858585;
}
@media print {
  div#reportPage .image {
    -webkit-print-color-adjust: exact;
  }
}
div#reportPage .details {
  flex: 4;
}
div#reportPage .details .title {
  padding: 10px 20px;
  background-color: #eee;
  border-bottom: 2px solid #868686;
}
div#reportPage .details .detailFields {
  padding: 20px;
  max-height: 320px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media print {
  div#reportPage .details .detailFields {
    max-height: initial;
  }
}
div#reportPage .title {
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 40px;
}
div#reportPage .upcounter_collection {
  background: #303030;
  padding: 20px 10px 12px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border-top: 2px solid #858585;
  border-bottom: 2px solid #8b8b8b;
  z-index: 3;
}
@media print {
  div#reportPage .upcounter_collection {
    background: white;
  }
}
div#reportPage .xpopupLine {
  margin-bottom: 10px;
  min-width: 50%;
}
div#reportPage .xpopupQuestion {
  font-weight: bold;
  margin-bottom: 3px;
}
div#reportPage .xpopupAnswer {
  font-weight: lighter;
  margin-left: 20px;
}
div#reportPage .ui.statistic > .label {
  font-weight: lighter;
}
div#reportPage .ui.statistic > .label,
div#reportPage .ui.statistic > .value {
  color: white;
}
@media print {
  div#reportPage .ui.statistic > .label,
  div#reportPage .ui.statistic > .value {
    color: black;
  }
}
div#reportPage .chart_wrapper {
  padding: 30px 0 20px;
}
div#reportPage .chart {
  max-width: 400px;
  margin: 0 auto;
}
.DayPicker_portal__horizontal {
  top: 40%;
}
.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.5);
}
.CalendarDay__hovered_span {
  background: #94c6ff;
  border: 1px double #ffffff;
}
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover {
  background: #61abff;
  border: 1px double #94c6ff;
}
.CalendarDay__selected_span {
  background: #005dc7;
  border: 1px double #94c6ff;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #2e8fff;
  border: 1px double #94c6ff;
}
.timebuttons_line {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.timebuttons {
  padding: 22px 22px 12px;
  border-top: 2px double #858585;
  background: #303030;
}
.timebuttons .button {
  margin-bottom: 10px;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #004594;
  border: 1px double #61abff;
}
.logbuch .ui.inverted.pointing.secondary.menu {
  background: #303030;
  border-left: 0;
  border-right: 0;
  margin-bottom: 0;
  border-color: #848585;
}
.logbuch .ui.inverted.pointing.secondary.menu a.item {
  min-height: 50px;
}
.logbuch .ui.inverted.pointing.secondary.menu a.item.active {
  background: #777;
  border-color: #848585;
}
.logbuch .ui.tab,
.logbuch .attachments {
  padding: 20px;
}
.signatureImage {
  width: 158px;
  height: 40px;
  margin-left: 12px;
  border: 1px solid black;
}
.signature-checkbox {
  padding-bottom: 12px;
}
.table-questionary {
  table-layout: fixed;
}
.table-questionary-cell {
  overflow-wrap:  anywhere;
}
#content {
  width: 100%;
  background: #ffffff;
  margin-top: 10px;
  border-collapse: collapse;
  font-size: 14px;
  display: table;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.row {
  display: table-row;
  padding: 8px;
}
.cell-header {
  padding: 8px;
  display: table-cell;
  text-align: left;
  background: rgba(0, 0, 0, 0.03);
  font-weight: 700;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.95);
  border-right: none;
}
.cell-left {
  display: table-cell;
  padding: 8px;
  background: rgba(0, 0, 0, 0.03);
  font-weight: normal;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.95);
  width: 50%;
}
.cell-right {
  display: table-cell;
  padding: 8px;
  color: #000000;
  text-align: left;
}
.dummy-header {
  border-left: none;
}
.signature-checkbox {
  padding-bottom: 12px;
}
.signatureImage {
  width: 158px;
  height: 40px;
  margin-left: 12px;
  border: 1px solid black;
}
/*! system-font.css v2.0.2 | CC0-1.0 License | github.com/jonathantneal/system-font-css */
@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 300;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Segoe UI Light"), local("Ubuntu Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}
@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 300;
  src: local(".SFNSText-LightItalic"), local(".HelveticaNeueDeskInterface-Italic"), local(".LucidaGrandeUI"), local("Segoe UI Light Italic"), local("Ubuntu Light Italic"), local("Roboto-LightItalic"), local("DroidSans"), local("Tahoma");
}
@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 400;
  src: local(".SFNSText-Regular"), local(".HelveticaNeueDeskInterface-Regular"), local(".LucidaGrandeUI"), local("Segoe UI"), local("Ubuntu"), local("Roboto-Regular"), local("DroidSans"), local("Tahoma");
}
@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 400;
  src: local(".SFNSText-Italic"), local(".HelveticaNeueDeskInterface-Italic"), local(".LucidaGrandeUI"), local("Segoe UI Italic"), local("Ubuntu Italic"), local("Roboto-Italic"), local("DroidSans"), local("Tahoma");
}
@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 500;
  src: local(".SFNSText-Medium"), local(".HelveticaNeueDeskInterface-MediumP4"), local(".LucidaGrandeUI"), local("Segoe UI Semibold"), local("Ubuntu Medium"), local("Roboto-Medium"), local("DroidSans-Bold"), local("Tahoma Bold");
}
@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 500;
  src: local(".SFNSText-MediumItalic"), local(".HelveticaNeueDeskInterface-MediumItalicP4"), local(".LucidaGrandeUI"), local("Segoe UI Semibold Italic"), local("Ubuntu Medium Italic"), local("Roboto-MediumItalic"), local("DroidSans-Bold"), local("Tahoma Bold");
}
@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 700;
  src: local(".SFNSText-Bold"), local(".HelveticaNeueDeskInterface-Bold"), local(".LucidaGrandeUI"), local("Segoe UI Bold"), local("Ubuntu Bold"), local("Roboto-Bold"), local("DroidSans-Bold"), local("Tahoma Bold");
}
@font-face {
  font-family: system-ui;
  font-style: italic;
  font-weight: 700;
  src: local(".SFNSText-BoldItalic"), local(".HelveticaNeueDeskInterface-BoldItalic"), local(".LucidaGrandeUI"), local("Segoe UI Bold Italic"), local("Ubuntu Bold Italic"), local("Roboto-BoldItalic"), local("DroidSans-Bold"), local("Tahoma Bold");
}
